<template>
  <div class="knowledge-base max-w-lg mr-auto ml-auto">
    <JayaTitle :moreInfo="moreInfo" classType="page-title" titleName="Fiches pratiques"></JayaTitle>
    <SearchBar class="mt-2" @update-search="search"/>

    <div v-if="$store.state.account.user.is_superuser" class="mt-1 d-flex justify-content-center">
      <b-button variant="primary" @click="newHelpSheet"> Créer une fiche pratique</b-button>
    </div>
    <div class="mt-2">
      <b-card
          v-for="sheet in sheets"
          :key="sheet.id"
          class="mb-50 cursor-pointer max-w-md" no-body
          @click="loadHelpSheet(sheet)">
        <b-row class="help-sheet-card-height" no-gutters>
          <b-col class="d-flex justify-content-center" cols="3" md="2">
            <b-card-img
                v-if="sheet.local_authority_infos && sheet.local_authority_infos.photo_url"
                :alt="sheet.local_authority_infos.name + ' logo'"
                :src="getImage(sheet.local_authority_infos.photo_url)"
                class="help-sheet-card-image p-25"
                left/>
            <b-card-img
                v-else
                alt="Icon pelle"
                class="help-sheet-card-image p-25"
                left
                src="@/assets/images/icons/tools/shovel.svg"/>
          </b-col>
          <b-col cols="9" md="10">
            <b-card-body>
              <b-card-text>
                <h4 class="m-0 font-weight-bolder text-truncate">{{ sheet.title }}</h4>
                <div class="open-text">Ouvrir la fiche ...</div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <JayaPopUp ref="popUpHelpSheet" name="FichePratique">
      <EditableHelpSheet
          v-if="$store.state.account.user.is_superuser"
          :sheet="activeSheet"
          @cancelModifications="$refs.popUpHelpSheet.closePopUp()"
          @deleted="removeHelpSheet"/>
      <HelpSheet v-else :sheet="activeSheet"/>
    </JayaPopUp>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import JayaPopUp from '@/components/global/JayaPopUp.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import SearchBar from "@/components/global/SearchBar.vue"
import {BButton, BCard, BCardBody, BCardFooter, BCardImg, BCardText, BCol, BRow} from "bootstrap-vue";
import HelpSheet from '@/components/help-sheets/HelpSheet.vue'
import EditableHelpSheet from "@/components/help-sheets/EditableHelpSheet"


export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BCardFooter,
    BRow,
    BCol,
    JayaTitle,
    TextBlock,
    JayaPopUp,
    SearchBar,
    HelpSheet,
    EditableHelpSheet,
  },
  data() {
    return {
      moreInfo: "Ici, vous retrouvez toutes les fiches techniques d'aide au jardin",
      sheets: null,
      activeSheet: null,
      gotSheets: false,
      routerUpdate: false,
      firstInput: true,
      creatingSheet: false,
      searchText: "",
      searchOffset: 0,
      waitScroll: false,
      next: null,
      filtred: false
    }
  },
  props: {
    searchLimit: {
      type: Number,
      default: 12
    },
  },
  mounted() {
    this.$store.dispatch("helpSheet/search")
        .then(response => {
          this.sheets = response.data
          this.gotSheets = true
        })
        .catch(() => {
          this.sheets = null
          this.gotSheets = false
        })
  },
  methods: {
    loadHelpSheet(sheet) {
      this.$store.dispatch("helpSheet/get", sheet.id)
          .then(response => {
            this.activeSheet = response.data
            this.$refs.popUpHelpSheet.openPopUp()
          })
          .catch((error) => {
            if (error.response && error.response.status === 404 && sheet.newSheet) {
              this.$refs.popUpHelpSheet.openPopUp()
            } else {
              this.activeSheet = null
              this.gotSheets = false
            }
          })
    },
    getImage(photo_url) {
      return process.env.VUE_APP_BACKEND_URL + '/..' + photo_url + "&size=350"
    },
    newHelpSheet() {
      let newHelpSheet
      newHelpSheet = {
        id: this.sheets.length + 1,
        title: "Fiche en création",
        introduction: "Introduction",
        paragraphs: [],
        photos: [],
        newSheet: true
      }
      this.sheets.unshift(newHelpSheet)
      this.creatingSheet = true
      this.activeSheet = newHelpSheet
      this.$refs.popUpHelpSheet.openPopUp()
    },
    removeHelpSheet(id) {
      this.sheets = this.sheets.filter(e => {
        return e.id !== id
      })
      this.$refs.popUpHelpSheet.closePopUp()
    },
    search(searchText) {
      this.searchText = searchText
      this.searchOffset = 0
      let payload = {
        limit: this.searchLimit,
      }
      if (this.searchText !== "") {
        payload.search_text = this.searchText
      }
      this.$store.dispatch("helpSheet/search", payload)
          .then(response => {
            this.sheets = response.data
            this.gotSheets = true
          })
          .catch(() => {
            this.sheets = null
            this.gotSheets = false
            this.$notify({
              "title": "Erreur",
              "text": "La recherche n'a pas abouti, veuillez réessayer",
              "color": "danger",
              "time": 4000
            })
          })
    },
    scroll(event) {
      /*
      If we are at the bottom of the scroll bar during scrolling, load the next question.
      If we are on the questions tab, we load the questions from other users, on 'mes messages'
      we load the questions of the user
      */
      if (this.next && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        if (!this.waitScroll) {
          this.waitScroll = true
          this.currentScroll = event.target.scrollTop
          let payload = {
            limit: this.searchLimit,
            offset: this.searchOffset + this.searchLimit
          }
          if (this.searchText !== "") {
            payload.search_text = this.searchText
          }
          this.$store.dispatch("helpSheet/search", payload)
              .then(response => {
                this.next = response.data.next;
                this.searchOffset = this.searchOffset + this.searchLimit
                this.sheets = this.sheets.concat(response.data.results)
                this.gotSheets = true
                this.waitScroll = false
              })
              .catch(() => {
                this.waitScroll = false
                this.sheets = null
                this.gotSheets = false
                this.$notify({
                  "title": "Erreur",
                  "text": "La recherche n'a pas abouti, veuillez réessayer",
                  "color": "danger",
                  "time": 4000
                })
              })
        }
      }
    },
  },
  watch: {
    '$store.state.app.windowWidth'() {
      this.$refs.sheetTabs.activateTab(this.$refs.sheetTabs.currentTab)
    }
  }
}
</script>

<style lang="scss">
.knowledge-base {
  .help-sheet-card-height {
    height: 60px;
  }

  .help-sheet-card-image {
    object-fit: contain;
    height: 60px;
    width: 100%;
  }

  .open-text {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-style: italic;
    color: var(--primary);
  }
}
</style>
